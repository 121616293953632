<template>
  <div class="SensitiveMonitor">
    <!-- <div class="title">
      <h4 class="breadtitle">崩溃详情</h4>
    </div> -->
    <!-- <navigation /> -->

    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.startTime"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.endTime"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <!-- <el-form-item>
          行为类型
          <el-select v-model="formInline.reason" placeholder="崩溃类型" size="small"  class="location">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option> </el-select
        ></el-form-item> -->
      <!-- 位置 -->
      <el-col :span="3">
        <el-cascader
          style="width: 100%"
          size="small"
          :options="cityOptions"
          collapse-tags
          :props="props"
          v-model="formInline.location"
          placeholder="位置"
          filterable
          class="placeInput"
        ></el-cascader>
      </el-col>
      <select-input
        :options="devNameOptions"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <el-col :span="3">
        <!-- <el-button size="small" type="primary" @click="onSubmit">查询</el-button> -->
        <el-button
          size="small"
          type="primary"
          @click="advancedBtn"
          >导出</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="4" class="elRow">
      <!-- 点击选中只显示对应内容 -->
      <div
        style="
           display: inline-block;
           vertical-align: middle;
           position: absolute;
           right: 5px;
           top: -32px;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="item.eng"
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="
              width: 20px;
              height: 20px;
            "
          />
        </el-popover>
      </div>

      <!-- 表格 -->
      <!-- 
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }" -->
      <el-table
        size="mini"
        ref="myTable"
        :data="tableData"
        stripe
        fit
        class="tableClass"
        tooltip-effect="dark"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          width="120"
          fixed
          :show-overflow-tooltip="true"
          label="设备名称"
          v-if="tradeSelections[tradeSelections.length - 18].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          fixed
          :show-overflow-tooltip="true"
          label="崩溃类型"
          v-if="tradeSelections[tradeSelections.length - 17].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="应用名称"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="应用版本"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.version }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="系统版本"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="位置"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="经纬度"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            <span
              class="detaildesc"
              v-if="scope.row.longitude == '' || scope.row.latitude == ''"
            ></span>
            <span class="detaildesc" v-else
              >{{ scope.row.longitude }},{{ scope.row.latitude }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="上网方式"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.netType }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="外网IP"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.outIp }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="内网IP"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.ip }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="WIFI-SSID"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiSsid }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="WIFI-MAC"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiMac }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="用户名"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="姓名"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
          label="部门"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">
              {{ row.groupfullname.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          :show-overflow-tooltip="true"
          label="产生时间"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.time | dateformat }}
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          :show-overflow-tooltip="true"
          label="上报时间"
          prop="CREATETIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.createtime | dateformat }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span
              type="text"
              size="small"
              @click="details(scope.row)"
              style="color: #d38a08; cursor: pointer"
              >详情</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="'详情'"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      :num="'470px'"
    >
      <analysisDetail :detailData="detailData" />
    </Eldialog>
    <!-- <el-dialog
      id="detailBox"
      title="详情"
      :visible.sync="outerVisible"
      width="655px"
      style="margin-top: 5%"
    >
      <analysisDetail :detailData="detailData" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="outerVisible = false" size="mini"
          >关闭</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import { regionData } from "element-china-area-data"; //引入
import options from "@/utils/country-level3-data"; //三级城市数据
import pagination from "@/components/pagination/page";
import Eldialog from "../../components/elDialog/index.vue";
import analysisDetail from "./components/analysisDetail.vue";
import navigation from "@/components/hearder/index.vue";
const Base64 = require("js-base64").Base64;
export default {
  components: { selectInput, pagination, Eldialog, analysisDetail, navigation },

  // filters: {
  //   timefilters(val) {
  //     if (val === null || val === "") {
  //       return "暂无时间";
  //     }
  //     const d = new Date(val); //获取到的后台时间
  //     const month =
  //       d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  //     const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  //     const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  //     const min = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  //     const sec = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
  //     const times = `${d.getFullYear()}-${month}-${day} ${hours}:${min}:${sec}`;
  //     return times;
  //   },
  // },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      pickerOptions1: {},
      pickerOptions0: {},

      tradeSelections1: [],
      // 联动
      key: 1, //table  key
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      tradeSelections: [
        { eng: "devicename", name: "设备名称", isChecked: true, flag: true },
        { eng: "type", name: "崩溃类型", isChecked: true, flag: true },
        // { eng: "level", name: "行为级别", isChecked: true, flag: false },
        { eng: "name", name: "应用名称", isChecked: true, flag: true },
        { eng: "version", name: "应用版本", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: true },
        { eng: "location", name: "位置", isChecked: false, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: false, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: true, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: true, flag: false },
        { eng: "time", name: "产生时间", isChecked: true, flag: false },
        { eng: "createtime", name: "上报时间", isChecked: true, flag: true },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      //   分页
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      outerVisible: false,
      tableData: [], //列表data
      detailData: {}, //详情data
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      // typeOptions: [{ type: "", label: "崩溃类型" }], //行为类型
      devNameOptions: [
        { value: "devicename", label: "设备名称" },
        { value: "username", label: "姓名" },
        { value: "loginname", label: "用户名" },
        { value: "groupfullname", label: "部门" },
        // { value: "osVersion", label: "系统版本" },
      ], //设备名称
      formInline: {
        // 选择日期
        startTime: "",
        endTime: "",
        //下拉
        reason: "",
        location: [],
        deviceName: "设备名称",
        // 设备名称
        devName: "",
        input: "",
      },
      placeholder: "设备名称",
      seleShift: "devicename", // 选中下拉框转译
      // options: [],
      props: { multiple: true },
      selectedOptions: [], //存放默认值
      cityOptions: options, // 城市数据
      formCustom: {
        Detail: "", //详细地址信息
        DetailMap: "", //城市地址详细地址拼接
        address: "", //省份城市
      },
      // 配置项数据
      tbodyData: [],
      number: 0,
      number1: 0,
      orderRule: "DESC",
      orderColume: "CREATETIME",
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {},

  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    // 原代码日期
    this.formInline.startTime = this.$common.getAnyMonth("-", -1);
    this.formInline.endTime = this.$common.getDate();
    this.getDataList();
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 排序@sort-change="sort"
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      // this.getDataList();
      this.onSubmit(); //再次调用查询接口
    },
    // 详情弹框关闭
    handleClose() {
      this.outerVisible = false;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用方法
      this.getDataList(this.selectVal);
    },
    tableTitleTime(i, k) {
      // console.log(i, k);
    },
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "type" ||
          index == "name" ||
          index == "osVersion" ||
          index == "version" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSsid" ||
          index == "wifiMac" ||
          index == "username" ||
          index == "loginname" ||
          index == "groupfullname" ||
          index == "time" ||
          index == "createtime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
    changeDate() {
      let start = new Date(this.formInline.startTime).getTime();
      let end = new Date(this.formInline.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },

    // 列表
    async getDataList(val) {
      this.selectVal = val;
      // //一个月前日期
      // var getAnyMonth = function (symbol, n) {
      //   var symbol = symbol || "-";
      //   var nowDate = new Date();
      //   nowDate.setMonth(nowDate.getMonth() + n);
      //   var year = nowDate.getFullYear();
      //   var month = nowDate.getMonth() + 1;
      //   var day = nowDate.getDate();
      //   day = day < 10 ? "0" + day : day;
      //   month = month < 10 ? "0" + month : month;
      //   return year + symbol + month + symbol + day;
      // }; //当前日期
      // var getDate = function () {
      //   var date = new Date();
      //   var year = date.getFullYear();
      //   var mon = date.getMonth() + 1;
      //   var day = date.getDate();
      //   return (
      //     year +
      //     "-" +
      //     (mon < 10 ? "0" + mon : mon) +
      //     "-" +
      //     (day < 10 ? "0" + day : day)
      //   );
      // };
      // this.formInline.startTime = getAnyMonth("-", -1);
      // this.formInline.endTime = getDate();
      this.changeDate();
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: "",
        level: "",
        deviceName: "",
         
        location: location,
        customKey: this.selectVal ? this.selectVal.deviceName : "",
        customVal: this.selectVal ? this.selectVal.InputText : "",
      };
      // console.log(parma);
      const res = await this.$axios.post(
        "/httpServe/appCrash/getDataInfo",
        parma,
        true
      );
      // console.log(res.data);
      this.tableData = res.data.content;
      this.total = res.data.total;
      for (var i = 0; i < this.tableData.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i];
        // console.log(obj2, "obj2");
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
      }
      // this.tableData.sort((a, b) => {
      //   return (
      //     this.$moment(b.createtime).this.selectValueOf() - this.$moment(a.createtime).valueOf()
      //   );
      // });
    },
    changeName(item) {
      // console.log(item);
      this.placeholder = item;
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
          console.log(this.seleShift);
        }
      });
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var a = JSON.stringify(this.formInline.location);
      var b = JSON.parse(a);

      var arr = [];
      if (this.formInline.location.length > 0) {
        arr = b[0].join("/");
      }
      this.currentPage = 1;
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: this.formInline.reason,
        level: this.formInline.level,
        deviceName: "",
        location: location,
         
        customKey: val.deviceName,
        customVal: val.InputText,
      };
      const res = await this.$axios.post(
        "/httpServe/appCrash/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    //导出
    async advancedBtn() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != "操作") {
          arrOut.push(item.eng); //去重
          this.tradeSelections1 = [...new Set(arrOut)];
          // this.tradeSelections1.unshift("name", "battery")
        }
      });

      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(escape(atob(token2.split(".")[1])));

      //  alert("userString=="+userString);
      let groupId = JSON.parse(userString).userId;
      //  alert("groupId==="+groupId)
      this.userId = groupId;

      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var a = JSON.stringify(this.formInline.location);
      var b = JSON.parse(a);

      var arr = [];
      if (this.formInline.location.length > 0) {
        arr = b[0].join("/");
      }

      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: this.formInline.reason,
        level: this.formInline.level,
        location: location,
        deviceName: "",
         
        customKey: this.selectVal ? this.selectVal.deviceName : this.seleShift,
        customVal: this.selectVal
          ? this.selectVal.InputText
          : this.formInline.input,
        exportField: this.tradeSelections1,
      };
      // console.log(parma);
      const res = await this.$axios.postExport(
        "/httpExport/appCrash/exportExcel",
        parma,
        true,
        this.userId
      );
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportCrash_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    async details(info) {
      // console.log(info, "info");
      var parma = { id: info.id };
      const res = await this.$axios.post(
        "/httpServe/appCrash/getData",
        parma,
        true
      );
      // console.log(res.data.content);
      var conTit = Base64.decode(res.data.content);
      res.data.content = conTit;
      console.log(res.data.content);
      // console.log(a,"666666");
      // console.log(JSON.parse(a));
      this.outerVisible = true;
      this.detailData = res.data;
    },
    //配置项监听事件
    tableTitleTime(event) {
      // console.log(event, "监听表格配置项");
      // console.log(this.checkList, "kongshuzu");
    },
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.el-input__inner {
  height: 28px;
  line-height: 28px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 135px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}

.el-form {
  width: 100%;
  margin-left: 0;
  margin-top: 0;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}
.el-form-item__content {
  width: 100%;
  /* display: flex; */
  margin: 0 !important;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}
</style>