<template>
  <div>
    <div class="detailContent">
      <!-- 应用信息 -->
      <p class="detailTitle">{{ $t('MobileOperations.CrashInfor.AppInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationName')}}：</span>
            <span class="detaildesc">{{ detailData.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationVersion')}}：</span>
            <span class="detaildesc">{{ detailData.version }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.SdkVersion')}}：</span>
            <span class="detaildesc">{{ detailData.sdkVersion }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.AppPackageName')}}：</span>
            <span class="detaildesc">{{ detailData.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">{{ $t('MobileOperations.CrashInfor.AppMD5')}}：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.appMd5 }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <p class="detailTitle">{{ $t('MobileOperations.CrashInfor.BasicInformation')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.DeviceName')}}：</span>
            <span class="detaildesc">{{ detailData.devicename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.Name')}}：</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.EquipmentModel')}}：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备厂商：</span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel">合规状态：</span>
            <span class="detaildesc"></span>
          </div> -->
          <div class="group">
            <span class="detailLabel">{{ $t('public.SystemVersion')}}：</span>
            <span class="detaildesc">{{ detailData.osVersion}}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.DeviceIP')}}：</span>
            <span class="detaildesc">{{ detailData.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('public.Username')}}：</span>
            <span class="detaildesc">{{ detailData.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.UserDepartment')}}：</span>
            <span class="detaildesc" v-if="detailData.groupfullname">{{ detailData.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.devicename }}</span>
          </div> -->
          <!-- <div class="group">
            <span class="detailLabel">设备越狱：</span>
            <span class="detaildesc">{{ detailData.devicename }}</span>
          </div> -->
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.EquipmentIMEI')}}：</span>
            <span class="detaildesc"  v-if="detailData.imei == ''">N/A</span>
            <span class="detaildesc"  v-else>{{ detailData.imei }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.InternetMethod')}}：</span>
            <span class="detaildesc">{{ detailData.netType }}</span>
          </div>
        </div>
      </div>
      <!-- 位置信息 -->
      <p class="detailTitle">{{ $t('MobileOperations.CrashInfor.PositionInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.Country')}}：</span>
            <span class="detaildesc">{{ detailData.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.Province')}}：</span>
            <span class="detaildesc">{{ detailData.province }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.City')}}：</span>
            <span class="detaildesc">{{ detailData.city }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.LatitudeLongitude')}}：</span>
            <span class="detaildesc" v-if="detailData.longitude == '' || detailData.latitude == ''">N/A</span>
            <span class="detaildesc" v-else>{{ detailData.longitude }},{{ detailData.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.Address')}}：</span>
          <span class="detaildesc">{{ detailData.location }}</span>
        </div>
      </div>
      <!-- 风险信息 -->
      <p class="detailTitle">{{ $t('MobileOperations.CrashInfor.CrashInfo')}}：</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.Crashtype')}}：</span>
            <span class="detaildesc">{{ detailData.type }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.GenerationTime')}}：</span>
            <span class="detaildesc">{{
              detailData.time | dateformat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('public.ReportingTime')}}：</span>
            <span class="detaildesc">{{
              detailData.createtime | dateformat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{ $t('MobileOperations.CrashInfor.CrashContent')}}：</span>
          <el-input type="textarea" :rows="10" v-model="detailData.content" disabled style="margin-top:5px"> </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detail',

  props: {
    detailData: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      // detailData:{},
    }
  },
  // watch: {
  //   detailData(newVal, oldVal) {
  //      // 对于为空的字段，显示N/A
  //     for (var i = 0; i < newVal.length; i++) {
  //       var obj2 = newVal[i]
  //       console.log(obj2, "obj2");
  //       for (var key in obj2) {
  //         if (obj2[key] == null || obj2[key] == '') {
  //           obj2[key] = 'N/A'
  //         }
  //       }
  //     }
  //     this.detailData = newVal
  //     console.log(this.detailData,"this.detailData")
  //   },
  //   // 开启深度监听
  //   deep: true,
  // },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;
  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }
  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;
    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 44%;
          text-align: left;
        }
        .detaildesc {
          width: 56%;
        }
      }
    }
    .boxRight {
      width: 50%;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 23%;
          text-align: left;
        }
        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }
  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;
    .group {
      margin-top: 8px;
      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }
      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}
</style>